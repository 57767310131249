import 'common/theme/main.scss';
import { CircularLoader, TournamentCard } from '../../components';
import './styles.scss';
import { useBffTournaments } from '../../hooks/use-tournaments/use-tournaments';
import { BackgroundImageContainer } from 'components/atoms/background-image-container/background-image-container';
import { useAuth } from 'contexts/authentication';
import {
  BspotCashierMessage,
  postNativeMessage,
} from 'services/native-ios/native-ios';
import React, { useEffect } from 'react';
import { TournamentList } from 'components/organisms/tournament-section/tournament-list/tournament-list';
import { AppLoader } from 'components/molecules/app-loader/app-loader';
import { FadeInContainer } from 'pages/make-deposit/styled';

export function TournamentsLobbyPage() {
  return (
    <div style={{ overflowX: 'hidden' }}>
      <div className='d-flex flex-column gap-3 gap-lg-5 lobby-container'>
        <Tournaments />
      </div>
    </div>
  );
}

const Tournaments = () => {
  const {
    data: tournaments,
    refetch: refetchTournaments,
    status,
  } = useBffTournaments();
  const noTournamentsAfterLoad =
    status === 'error' || (status === 'success' && tournaments?.length === 0);

  const { auth } = useAuth();

  useEffect(() => {
    if (status === 'success' && tournaments?.length === 0) {
      postNativeMessage(BspotCashierMessage.NO_TOURNAMENTS);
    }
  }, [status, tournaments]);

  if (noTournamentsAfterLoad) {
    return null;
  }

  return (
    <BackgroundImageContainer width='100%' theme='green'>
      <section className='lobby-tournaments'>
        <div className='app-container'>
          <div className='d-flex flex-column justify-content-center'>
            <h1 style={{ maxWidth: '350px' }} className='align-self-center'>
              <img
                style={{ width: '100%' }}
                className='align-self-center'
                src='/assets/images/tournament-badge-white.webp'
                alt='tournament-badge'
              />
            </h1>
            <p className='text-center'>
              Play and win in our <b>BIG PRIZE</b> tournaments! Earn points,
              climb the leaderboard and score big with
              <b> $1000s in Bonus Credits</b> up for grabs.
            </p>
          </div>
        </div>
        <div className='card'>
          {status === 'loading' && (
            <FadeInContainer className='d-flex col-12 flex-column mx-auto pb-3'>
              <div
                className=''
                style={{
                  display: 'flex',
                  padding: '150px 0',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <AppLoader fullHeight={false} />
              </div>
            </FadeInContainer>
          )}
          {status !== 'loading' && tournaments.length > 0 && (
            <TournamentList
              tournaments={tournaments}
              playerId={auth?.session?.playerId.toString()}
              refetchTournaments={refetchTournaments}
            />
          )}
        </div>
      </section>
    </BackgroundImageContainer>
  );
};
